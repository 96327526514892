<template>
  <el-main v-if="info.id">
    <div class="header">
      <p>会员编号：{{ info.id }}</p>
      <el-button type="primary" size="small" v-for="(item, index) in info.identity_type" :key="index">{{ item | identityName }}</el-button>
    </div>
    <div class="user-info">
      <div class="title">用户信息</div>
      <div class="content padding between">
        <div class="left">
          <p>
            会员头像：
            <el-image :src="info.user_avatar"></el-image>
          </p>
          <p>会员昵称：{{ info.nickname }}</p>
          <p>会员姓名：{{ info.name }}</p>
          <p>联系电话：{{ info.mobile ? info.mobile : '--' }}</p>
          <p>所属区域：{{ info.nickname }}</p>
          <p>收货地址：{{ info.address || '--' }}</p>
          <p v-if="info.deposit_account">企业名称：{{ info.enterprise_name || '--' }}</p>
          <p v-if="info.deposit_account">开户行账号：{{ info.deposit_account || '--' }}</p>
          <p>注册时间：{{ info.create_time | getDateformat }}</p>
          <p class="card" v-if="info.id_card_image_z">
            身份证：
            <el-image :src="info.id_card_image_z" fit="cover" :preview-src-list="[info.id_card_image_z, info.id_card_image_f]"></el-image>
            <el-image :src="info.id_card_image_f" fit="cover" :preview-src-list="[info.id_card_image_z, info.id_card_image_f]"></el-image>
          </p>
          <p class="license" v-if="info.license">
            营业执照：
            <el-image :src="info.license" fit="cover" :preview-src-list="[info.license]"></el-image>
          </p>
        </div>
      </div>
    </div>
    <div class="shop-info" v-if="info.shop_name">
      <div class="title">店铺信息</div>
      <div class="content padding between">
        <div class="left">
          <p>
            店铺logo：
            <el-image :src="info.shop_logo"></el-image>
          </p>
          <p>店铺名称：{{ info.shop_name }}</p>
          <p>店铺地址：{{ info.address || '--' }}</p>
        </div>
      </div>
    </div>
    <div class="asset-info" v-if="info.asset_information.length">
      <div class="title">资产信息</div>
      <div class="asset-total">
        <p>总计收益金额：{{ info.amount }}</p>
        <el-button type="text" @click="$router.push({ path: '/finance/gatewayMemberAssets', query: { member_id: id } })">查看收益</el-button>
        <p>现有积分数：{{ info.user_integral }}</p>
        <el-button type="text" @click="showIntegral = !0">查看积分明细</el-button>
      </div>
      <el-table :data="info.asset_information" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <el-table-column prop="service_type " label="收益类目" align="center">
          <template v-slot="{ row }">
            {{
              row.service_type == 1
                ? '时价'
                : row.service_type == 2
                ? '合伙人入驻'
                : row.service_type == 3
                ? '项目合作'
                : row.service_type == 4
                ? '项目活动'
                : row.service_type == 5
                ? '应用数据'
                : row.service_type == 6
                ? '实物商品'
                : row.service_type == 7
                ? '广告'
                : '关键词'
            }}
          </template>
        </el-table-column>
        <el-table-column prop="case_list" label="佣金类目" align="center">
          <template v-slot="{ row }">
            <p style="color: #f56c6c" v-for="(item, index) in row.case_list" :key="index">
              {{ item.identity_type == 0 ? '经营分佣' : item.identity_type == 1 ? '城市合伙人分佣' : item.identity_type == 2 ? '区域合伙人分佣' : '市场合伙人分佣' }}{{ item.percentage }}%
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="total_amount" label="收益金额" align="center">
          <template v-slot="{ row }">
            ￥{{ row.total_amount }}
            <el-button type="text" @click="$router.push({ path: '/finance/gatewayMemberAssets', query: { member_id: id, service_type: row.service_type } })">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="total_count" label="累计订单数" align="center"></el-table-column>
      </el-table>
    </div>
    <div class="withdraw-info">
      <div class="title">提现情况</div>
      <el-table :data="withdrawalLogo" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <el-table-column prop="can_withdraw_amount " label="现有余额（可提现金额）" align="center">
          <template v-slot="{ row }">￥{{ row.can_withdraw_amount }}</template>
        </el-table-column>
        <el-table-column prop="apply_sum" label="已提现金额" align="center">
          <template v-slot="{ row }">
            ￥{{ row.apply_sum }}
            <el-button type="text" @click="$router.push({ path: '/finance/gatewayWithdrawalList', query: { member_id: id, status: 2 } })">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="review_sum" label="审核中金额" align="center">
          <template v-slot="{ row }">
            ￥{{ row.review_sum }}
            <el-button type="text" @click="$router.push({ path: '/finance/gatewayWithdrawalList', query: { member_id: id, status: 1 } })">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="reject_sum" label="已驳回金额" align="center">
          <template v-slot="{ row }">
            ￥{{ row.reject_sum }}
            <el-button type="text" @click="$router.push({ path: '/finance/gatewayWithdrawalList', query: { member_id: id, status: -1 } })">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="积分明细" :visible.sync="showIntegral" width="600px" center>
      <el-table :data="integralLog" height="400px" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="create_time" label="时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.create_time | getDateformat }}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="用户行为" align="center">
          <template v-slot="{ row }">
            {{
              row.type == 1
                ? '分享'
                : row.type == 2
                ? '登录'
                : row.type == 3
                ? '签到'
                : row.type == 4
                ? '发布时价'
                : row.type == 5
                ? '发布资讯'
                : row.type == 6
                ? '购买服务商品'
                : row.type == 7
                ? '购买实物商品'
                : row.type == 8
                ? '订阅时价'
                : row.type == 9
                ? '申请合伙人'
                : row.type == 10
                ? '申请关键词'
                : row.type == 11
                ? '申请广告'
                : row.type == 12
                ? '申请合作'
                : '申请活动'
            }}
          </template>
        </el-table-column>
        <el-table-column prop="integral" label="积分" align="center">
          <template slot-scope="scope">
            {{ scope.row.type == 1 ? '+' + scope.row.integral : '-' + scope.row.integral }}
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @current-change="handleCurrentChange($event, 1)"
          :current-page="integralParameter.page"
          :page-size="integralParameter.rows"
          :total="integralParameter.total_number"
        ></el-pagination>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
export default {
  data() {
    return {
      id: '',
      info: {},
      withdrawalLogo: [],
      showIntegral: !1,
      integralLog: [],
      integralParameter: {
        page: 1,
        rows: 10,
        total_number: 0,
      },
    };
  },
  filters: {
    getDateformat: getDateformat,
    identityName(type) {
      let name = '';
      switch (type) {
        case 0:
          name = '个人会员';
          break;
        case 1:
          name = '企业会员';
          break;
        case 2:
          name = '个人会员商家';
          break;
        case 3:
          name = '企业会员商家';
          break;
        case 4:
          name = '供应商品商家';
          break;
        case 5:
          name = '代销商家';
          break;
        case 6:
          name = '产品合伙人';
          break;
        case 7:
          name = '城市合伙人';
          break;
        case 8:
          name = '市场合伙人';
          break;
      }
      return name;
    },
  },
  watch: {
    showIntegral(val) {
      if (val && !this.integralLog.length) this.getIntegralLog();
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.getInfo();
  },
  methods: {
    handleCurrentChange(val, type) {
      if (type == 1) {
        this.integralParameter.page = val;
        this.getIntegral();
      }
    },
    getInfo() {
      this.$axios
        .post(this.$api.gateway.member.info, {
          id: this.id,
          rows: 10,
          page: 1,
        })
        .then(res => {
          if (res.code == 0) {
            let info = res.result;
            info.identity_type = info.identity_type.split(',').map(Number);
            this.withdrawalLogo = [
              {
                apply_sum: info.apply_sum,
                review_sum: info.review_sum,
                can_withdraw_amount: info.can_withdraw_amount,
                reject_sum: info.reject_sum,
              },
            ];
            this.info = info;
          }
        });
    },
    getIntegralLog() {
      this.$axios
        .post(this.$api.gateway.member.integralLog, {
          member_id: this.id,
          page: this.integralParameter.page,
          rows: this.integralParameter.rows,
        })
        .then(res => {
          if (res.code == 0) {
            this.integralLog = res.result.list;
            this.integralParameter.total_number = res.result.total_number;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  font-size: 14px;

  .title {
    line-height: 45px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .padding {
    padding: 30px 0px 30px 0px;
  }
  .between {
    justify-content: space-between;
    padding: 20px 0px 20px 0px;
    .left {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    p {
      margin-right: 15px;
    }
  }
  .user-info,
  .shop-info {
    .content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      p {
        height: 50px;
        line-height: 50px;
        margin-right: 64px;
        margin-bottom: 15px;
      }
      p:nth-child(1) {
        display: flex;
        align-items: center;
        .el-image {
          width: 40px;
          height: 40px;
          margin-right: 8px;
        }
        img {
          width: 30px;
          height: 30px;
          margin-left: 10px;
        }
      }
    }
    .card,
    .license {
      display: flex;
      align-items: center;
      .el-image {
        width: 100px;
        height: 60px;
        margin-right: 10px;
      }
    }
  }
  .asset-info {
    .asset-total {
      display: flex;
      align-items: center;
      .el-button {
        margin: 0 40px;
      }
    }
  }
}
</style>